<template>
  <div class="licitacije_so">
    <v-card class="mx-2 my-4 mx-sm-4 my-sm-6 mx-md-8 my-md-12" elevation="5">
      <v-card-title>
        Biće povezana sa Globalnom aukcijom kada se završi
      </v-card-title>
      <v-card-text class="px-md-7">
        <p class="ceo-text first-child">
          Nunc pulvinar sapien et ligula ullamcorper malesuada proin libero
          nunc. At quis risus sed vulputate odio ut. Nisl suscipit adipiscing
          bibendum est ultricies integer quis auctor elit. Mi tempus imperdiet
          nulla malesuada pellentesque. Faucibus purus in massa tempor nec
          feugiat nisl pretium. Suspendisse faucibus interdum posuere lorem
          ipsum dolor sit. Sed viverra ipsum nunc aliquet bibendum enim.
          Pulvinar elementum integer enim neque. Maecenas pharetra convallis
          posuere morbi leo. Lorem dolor sed viverra ipsum nunc aliquet. Pretium
          fusce id velit ut tortor.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>