var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banka_so"},[_c('v-card',{staticClass:"mx-2 my-4 mx-sm-4 my-sm-6 mx-md-8 my-md-12",attrs:{"elevation":"6"}},[_c('v-card-title',{staticClass:"naslov"},[_vm._v("Banka ")]),_c('v-layout',{staticClass:"mx-auto",attrs:{"row":""}},[_c('v-flex',{staticClass:"pa-2 pa-sm-5 pa-md-5",attrs:{"xs12":"","sm12":"","md5":""}},[_c('v-card',{staticClass:"bank_card"},[_c('v-card-text',{staticClass:"px-1 px-md-7"},[_c('p',{staticClass:"ceo-text-naslov",class:_vm.$vuetify.breakpoint.xs
                  ? "ceo-text-naslov-xs"
                  : "ceo-text-naslov-smAndUp"},[_vm._v(" Humanitarna organizacija \"Solidarnost online\" Vam se zahvaljuje na uplatama ")]),_c('div',{staticClass:"novac"},[_c('p',{staticClass:"stanje_text"},[_vm._v("Doniran novac :")]),_c('p',{staticClass:"stanje_novac"},[_vm._v("14.350,00 RSD")])])]),_c('v-card-text',{staticClass:"px-1 px-md-7"},[_c('p',{staticClass:"ceo-text no-indent"},[_vm._v("Banka: "),_c('b',[_vm._v("Intesa DOO")])]),_c('p',{staticClass:"ceo-text no-indent"},[_vm._v(" Račun: "),_c('b',[_vm._v("123-123123123123-123")])]),_c('p',{staticClass:"ceo-text no-indent"},[_vm._v(" Poziv na broj: "),_c('b',[_vm._v("123-123123123-123")])]),_c('p',{staticClass:"ceo-text no-indent no-align"},[_vm._v(" Svrha uplate: "),_c('b',[_vm._v("za humanitarnu organizaciju Solidarnost Online")])])])],1)],1),_c('v-flex',{staticClass:"pa-2 pa-sm-5 pa-md-5",attrs:{"xs12":"","sm12":"","md7":""}},[_c('v-card',{staticClass:"bank_card"},[_c('v-card-text',{staticClass:"px-1 px-md-7"},[_c('p',{staticClass:"stanje_text"},[_vm._v("Uplate/isplate donacija")])]),_c('v-card-text',{staticClass:"px-1 px-md-7"},[_c('p',{staticClass:"datum"},[_vm._v("Datum: 25.09.2020.")]),_c('div',[_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-plus-square uplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v("Jovan Stević")])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("30.000,00 RSD")])])],1),_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-plus-square uplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v("Pera Perić")])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("500,00 RSD")])])],1),_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-plus-square uplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v("Jovana Jovanović")])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("1500,00 RSD")])])],1),_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-minus-square isplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v(" Za Draganu Kostić "),(_vm.$vuetify.breakpoint.smAndUp)?_c('i',{staticClass:"fas fa-info-circle blue--text ml-3"}):_vm._e()])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("50.000,00 RSD")])])],1),_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-plus-square uplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v("S.R \"Perović\"")])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("1.000,00 RSD")])])],1),_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-plus-square uplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v("anonimno")])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("500,00 RSD")])])],1)],1)]),_c('v-card-text',{staticClass:"px-1 px-md-7"},[_c('p',{staticClass:"datum"},[_vm._v("Datum: 24.09.2020.")]),_c('div',[_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-plus-square uplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v("Jovan Stević")])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("30.000,00 RSD")])])],1),_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-minus-square isplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v("Pera Perić")])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("25000,00 RSD")])])],1),_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-plus-square uplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v("Jovana Jovanović")])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("1500,00 RSD")])])],1),_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-minus-square isplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v(" Za Draganu Kostić "),(_vm.$vuetify.breakpoint.smAndUp)?_c('i',{staticClass:"fas fa-info-circle blue--text ml-3"}):_vm._e()])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("10.000,00 RSD")])])],1),_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-plus-square uplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v("S.R \"Perović\"")])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("1.000,00 RSD")])])],1),_c('v-row',{staticClass:"row_uplata_isplata",class:_vm.$vuetify.breakpoint.smAndUp
                    ? "row_uplata_isplata_smAndUp"
                    : "row_uplata_isplata_xs"},[_c('v-col',{staticClass:"column plus_minus",attrs:{"cols":"1"}},[_c('i',{staticClass:"fas fa-plus-square uplata"})]),_c('v-col',{staticClass:"column col_ime_prezime",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"ime_prezime"},[_vm._v("anonimno")])]),_c('v-col',{staticClass:"column col_cifra",attrs:{"cols":"4","sm":"3","md":"4"}},[_c('p',{staticClass:"cifra"},[_vm._v("500,00 RSD")])])],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }