var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"obavestenja_so"},[_vm._l((_vm.obavestenja_so),function(jedno_obavestenje,i){return _c('v-card',{key:i,staticClass:"ma-4 mx-2 my-4 mx-sm-4 my-sm-6 mx-md-8 my-md-12",attrs:{"elevation":"5"}},[_c('v-card-title',[_c('v-spacer'),_vm._v(" "+_vm._s(jedno_obavestenje.naslov)+" "),_c('v-spacer'),(_vm.get_privilegije)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){(_vm.dialog_edit_obavestenja_boolean = true),
                (_vm.edit_data.id_obavestenja = jedno_obavestenje.obav_id),
                (_vm.edit_data.naslov_obavestenja = jedno_obavestenje.naslov),
                (_vm.edit_data.text_obavestenja = jedno_obavestenje.text)}}},[_c('v-list-item-title',{staticClass:"item"},[_vm._v("Prepravka")])],1),_c('v-list-item',{on:{"click":function($event){(_vm.dialog_delete_obavestenja = true),
                (_vm.edit_data.id_obavestenja = jedno_obavestenje.obav_id),
                (_vm.edit_data.naslov_obavestenja = jedno_obavestenje.naslov)}}},[_c('v-list-item-title',{staticClass:"item"},[_vm._v("Brisanje")])],1)],1)],1):_vm._e()],1),_c('v-card-text',{staticClass:"px-md-7"},[_c('p',{staticClass:"ceo-text",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(jedno_obavestenje.text)+" ")])])],1)}),(_vm.dialog_edit_obavestenja_boolean)?_c('DialogEditObavestenja',{attrs:{"route":this.$route.path,"edit_data":_vm.edit_data}}):_vm._e(),_c('DialogBrisanjeObavestenja',{attrs:{"route":this.$route.path,"edit_data":_vm.edit_data}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }